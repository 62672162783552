import WeatherGrid from '../grids/WeatherGrid';
import ImageBackground from '../backgrounds/ImageBackground';
import ColorBackground from '../backgrounds/ColorBackground';
import ErrorMessage from '../messages/ErrorMessage';
import AnimationWrapper from '../wrappers/AnimationWrapper';

export default function Weather(props) {
  const {
    options: { days_count, show_background_color, show_background_image, show_time, is_compact, location },
    colors: { primaryColor : evenColor, secondaryColor: unevenColor }
  } = props;

  const weather = location?.weather;

  if (!weather?.forecast || !weather.forecast.length) {
    return <ErrorMessage message={"Weerinformatie kon niet worden opgehaald, controleer of de juiste locatie is ingesteld."} />
  }

  const Background = parseInt(show_background_image?.value) ? ImageBackground : ColorBackground;
  const weatherData = weather.forecast.slice(0, Math.min(weather.forecast.length, parseInt(days_count?.value)));

  return (
    <Background color={parseInt(show_background_color?.value) ? evenColor : "white"} imageUrl={weatherData[0]?.image} opacity={0.95} showBlur={true}>
      <AnimationWrapper animation={'fade-in'}>
        <WeatherGrid
          weatherData={weatherData}
          showWeatherCardColor={!!parseInt(show_background_color?.value)}
          showTime={!!parseInt(show_time?.value)}
          showCompact={!!parseInt(is_compact?.value)}
          evenColor={evenColor}
          unevenColor={unevenColor}
        />
      </AnimationWrapper>
    </Background>
  );
}
