import { DEFAULT_IMAGE_URL } from '../../constants';
import { useState } from 'react';

export default function Image({ src = DEFAULT_IMAGE_URL, alt = 'Image', width = '100%', height = '100%', useShadow = false, style }) {
  const [isLoading, setIsLoading] = useState(true);
  const [fetchFailed, setFetchFailed] = useState(false);

  return !fetchFailed ? (
    // Div to prevent the image from sizing to the parent container.
    <div style={{ display: 'flex' }} >
      <img
        src={src}
        alt={alt}
        onLoad={() => setIsLoading(false)}
        onError={() => setFetchFailed(true)}
        hidden={isLoading}
        style={{
          width: width,
          height: height,
          objectFit: 'cover',
          ...style,
          ...(useShadow && {
            WebkitFilter: 'drop-shadow(0px 0px 5px #ffffff)',
            filter: 'drop-shadow(0px 0px 8px #ffffff)',
          }),
        }}
      />
    </div>
  ) : null;
}
