import FancyPlayer from './FancyPlayer';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { screenComponentFactory } from '../../tools';
import { ShellContext } from '../../providers/shell';
import Error from '../../pages/Error';

export default function PlaylistPlayer(props) {
  const { screens, refetch } = props;
  const { updateShell } = useContext(ShellContext);

  // Use an object to force React to update this state, even when it's updated with the same value.
  const [position, setPosition] = useState({ data: 0 });

  // Use a ref so that the functions can access the actual screens instead of a copy.
  const screensRef = useRef(screens);
  const timeoutRef = useRef(0);
  const positionRef = useRef(0);

  // Copy screens to prevent errors that may occur with a slow connection or when the array changes.
  let safeScreens = screensRef.current;

  /**
   * Handle key events.
   */
  const onKeydown = (event) => {
    // Only handle arrow left/right key input.
    if (event.keyCode !== 37 && event.keyCode !== 39) {
      return;
    }

    // Stop timeout of current displayed screen.
    window.clearTimeout(timeoutRef.current);

    const currentPos = positionRef.current;
    setPosition({
      data: event.keyCode === 37
          ? ((currentPos - 1) >= 0 ? currentPos - 1 : safeScreens.length - 1) // Arrow left, move backwards.
          : ((currentPos + 1) % safeScreens.length)  // Arrow right, move forwards.
    });
  };

  /**
   * Handle a screen from whose display duration is expired.
   */
  const screenExpiredHandler = () => {
    const next = (position.data + 1) % safeScreens.length;

    // Update the safeScreens on restart. (at this moment with a reasonable internet speed, the refetch has been completed).
    if (next === 0) {
      safeScreens = screensRef.current;
    }

    // Render the next screen.
    setPosition({ data: next });

    // Refetch if on final screen of playlist.
    if (next === safeScreens.length - 1) {
      refetch();
    }
  };

  /**
   * Set the display duration for the screen to show.
   */
  const setScreenExpiration = (screen) => {
     const duration = safeScreens.length === 1
       ? 30000
       : (screen?.display_duration ?? 5) * 1000;

     timeoutRef.current = window.setTimeout(screenExpiredHandler, duration);
  };

  /**
   * Render the current screen of a playlist.
   */
  const render = (position) => {
    if (position >= safeScreens.length) {
      return <Error />;
    }

    const screen = safeScreens[position];

    setScreenExpiration(screen);

    // Return current screen to render.
    return screenComponentFactory(screen);
  };

  /**
   * Update the screensRef whenever the screens are changed (i.e. with a refetch).
   */
  useEffect(() => {
    screensRef.current = screens;
  }, [screens]);

  /**
   * Update the positionRef whenever the position is changed.
   */
  useEffect(() => {
    positionRef.current = position.data;
  }, [position]);

  useEffect(() => {
    window.addEventListener('keydown', onKeydown);

    return () => {
      window.removeEventListener('keydown', onKeydown);
      window.clearTimeout(timeoutRef.current);
    }
  }, []);

  // Use a memo to prevent an infinite refetch loop when a refetch has been done.
  return useMemo(() => {
    return (
      <FancyPlayer
        renderFunc={render}
        transitionTo={position.data}
        onEnter={() => updateShell(safeScreens[position.data])}
      />
    );
  }, [position]);
}
