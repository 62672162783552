import { QueryClient, QueryClientProvider } from 'react-query';
import PlaylistSettingsProvider from './playlistSettings';
import TimeProvider from './time';
import ShellProvider from './shell';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 300_000, // Refetch after 5 minutes.
      retry: 2, // Retry 2 times failing requests.
      retryDelay: 5000,
    },
  },
});

export default function Providers(props) {
  return (
    <QueryClientProvider client={queryClient}>
      <PlaylistSettingsProvider>
        <TimeProvider>
          <ShellProvider>{props.children}</ShellProvider>
        </TimeProvider>
      </PlaylistSettingsProvider>
    </QueryClientProvider>
  );
}
