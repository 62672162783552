export default function ImageWrapper(props) {
  const { children, center = false } = props;

  return center ? (
    <div style={{
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '10vmin 5vmin 10vmin 5vmin',
    }}>
      {children}
    </div>
  ) : children;
}
