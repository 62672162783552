import ImageBackground from '../backgrounds/ImageBackground';
import TextOverlay from '../overlays/TextOverlay';
import NewsPlayer from '../players/NewsPlayer';
import NewsGrid from '../grids/NewsGrid';
import ErrorMessage from '../messages/ErrorMessage';
import AnimationWrapper from '../wrappers/AnimationWrapper';
import { DEFAULT_IMAGE_URL } from '../../constants';

export default function News(props) {
  const {
    options: { news_count, screen_count, screen_time, title_only, url_1 },
    colors: { primaryColor },
  } = props;

  const articles = url_1?.news?.articles ?? [];

  /**
   * Render the proper screen type.
   * @returns {JSX.Element}
   */
  const render = function (index) {
    if (parseInt(title_only?.value)) {
      return <TextOverlay title={articles[index]?.title ?? "Nieuws kan niet worden verkregen."} overlayColor={primaryColor} />
    }

    const limitedCount = Math.min(3, news_count.value ?? 1); // Limit amount of articles to display to 3.
    const currentIteration = index * limitedCount;
    const slicedNews = articles.slice(currentIteration, currentIteration + limitedCount);

    // At least 2 and at max 3 columns.
    return <NewsGrid news={slicedNews} columns={Math.min(3, Math.max(2, news_count.value ?? 1))} logoUrl={url_1.news?.logo} />
  };

  return articles.length < 1 ? (
      <ErrorMessage message={"Vul een geldige RSS-feed in met artikelen om te tonen."} />
    ) : (
      <ImageBackground imageUrl={articles[0]?.image_url ?? DEFAULT_IMAGE_URL}>
        <NewsPlayer
          renderFunc={(i) => <AnimationWrapper animation={'fade-in'}>{render(i)}</AnimationWrapper>}
          interval={screen_time.value ?? 5} count={Math.min(screen_count.value ?? 1, articles.length)}
        />
      </ImageBackground>
  );
}
