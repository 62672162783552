import { useEffect, useState } from 'react';

export default function useElementSize(elementRef) {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const resize = () => {
    setWidth(elementRef?.current?.offsetWidth ?? 0);
    setHeight(elementRef?.current?.offsetHeight ?? 0);
  };

  /**
   * Handle element mount.
   */
  useEffect(() => {
    resize();
  }, [elementRef?.current]);

  /**
   * Handle resizes.
   */
  useEffect(() => {
    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  }, []);

  return { width, height };
}