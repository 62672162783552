import Grid from './Grid';
import Image from '../images/Image';
import TextBanner from '../banners/TextBanner';
import { useState } from 'react';

export default function NewsGrid({ news = [], logoUrl = '', columns = 2 }) {
  const [logoValid, setLogoValid] = useState(false);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        maxWidth: '160vmin',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingLeft: '10vmin',
        paddingRight: '10vmin',
      }}
    >
      <Grid columns={columns} rows={1} height={'auto'} spaceBetween={'2.5vmin'}>
        {/** Most elegant way to find out if the logo is valid and to prevent a possible CORS error. */}
        <img alt={''} src={logoUrl} onLoad={() => setLogoValid(true)} style={{display: 'none'}} />

        {/** The logo of the news source. */}
        {logoValid && (
          <div
            style={{
              position: 'fixed',
              marginLeft: '-9.8vmin',
              width: '10vmin',
              height: '10vmin',
              backgroundColor: 'white',
            }}
          >
            <Image src={logoUrl} alt={'news-source'} />
          </div>
        )}

        {news.map((newsItem) => (
          <TextBanner
            key={newsItem.title}
            header={newsItem.date}
            title={newsItem.title}
            content={newsItem.content}
          />
        ))}
      </Grid>
    </div>
  );
}
