import AppBrand from '../AppBrand';
import Loader from './Loader';
import MessageWrapper from '../wrappers/MessageWrapper';
import AnimationWrapper from '../wrappers/AnimationWrapper';

export default function FullPageLoader() {
  return (
    <AnimationWrapper animation={'blur-out'}>
      <MessageWrapper>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '5%',
            height: '100%',
            justifyContent: 'space-between',
            alignItems: 'center' }}
        >
          <AppBrand width={"75vmin"} height={"20vmin"} />

          <div style={{ flexBasis: '25%' }} >
            <Loader size={'20vmin'} />
          </div>

          <AnimationWrapper
            animation={'fade-in'}
            style={{
              width: 'auto',
              height: 'auto',
              opacity: 0,
              animationDelay: '5s',
              animationFillMode: 'forwards' }}
          >
            <p style={{ fontSize: '4vmin', }}>
              Dit lijkt langer te duren dan gepland! Even geduld alstublieft...<br/> <br/>
              Neem voor vragen contact met ons op via support@webba.nl of telefonisch via 0528 - 26 95 94.
            </p>
          </AnimationWrapper>
        </div>
      </MessageWrapper>
    </AnimationWrapper>
  );
}
