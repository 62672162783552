import { useContext } from 'react';
import { PlaylistSettingsContext } from '../providers/playlistSettings';
import { useQuery } from 'react-query';
import { fetchScreen } from '../api/screen';

export default function useScreenData(id) {
  const { setPlaylistSettings } = useContext(PlaylistSettingsContext);

  return useQuery(['screen'], () => fetchScreen(id), {
    onSuccess: data => setPlaylistSettings(data?.playlist),
  });
}
