import ReactPlayer from 'react-player';
import ErrorMessage from '../messages/ErrorMessage';
import { useEffect, useRef, useState } from 'react';
import Loader from '../spinners/Loader';
import AnimationWrapper from '../wrappers/AnimationWrapper';
import ColorBackground from '../backgrounds/ColorBackground';

export default function Video(props) {
  const {
    options: { video_1 },
    colors: { backgroundColor },
  } = props;

  const timeoutRef = useRef(0);
  const [isLoading, setIsLoading] = useState(true);
  const [hasFailed, setHasFailed] = useState(false);

  useEffect(() => {
    timeoutRef.current = window.setTimeout(() => setHasFailed(true), 5000);
    return () => window.clearTimeout(timeoutRef.current);
  }, []);

  if (!video_1?.value) {
    return <ErrorMessage message={"Stel een video in om te tonen."} />;
  }

  if (hasFailed) {
    return <ErrorMessage message={"De ingestelde video kan niet worden geladen."} />
  }

  return (
    <ColorBackground color={backgroundColor}>
      <AnimationWrapper animation={'fade-in'}>
        {isLoading && <Loader size={'20vmin'} />}

        <ReactPlayer
          url={video_1?.value}
          loop={true}
          playing={true}
          onStart={() => window.clearTimeout(timeoutRef.current)}
          onError={() => setHasFailed(true)}
          width={'100%'}
          height={'100%'}
          onPlay={() => setIsLoading(false)}
        />
      </AnimationWrapper>
    </ColorBackground>
  )
}
