import { useContext } from 'react';
import { TimeContext } from '../../../providers/time';
import HourLabel from './_hourLabel';

export default function Extended(props) {
  const {
    showSeconds,
    showDateAboveTime,
    useShortDate,
    showHourLabel,
    colors = { timeColor: '', dateColor: '', labelColor: '' }
  } = props;
  const { hour, minutes, seconds, date, shortDate } = useContext(TimeContext);

  const renderDate = () => (
    <span style={{ color: colors.dateColor, fontSize: `6vmin` }}>{(useShortDate ? shortDate : date).toUpperCase()}</span>
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {showDateAboveTime ? renderDate() : null}

      <div style={{ color: colors.timeColor }}>
        <span style={{ fontSize: '30vmin', display: 'inline-block', height: '30vmin', lineHeight: 1 }}>
          {hour}:{minutes}
        </span>

        {showSeconds ?
          <span style={{ fontSize: '15vmin', display: 'inline-block', width: '15vmin', height: '15vmin', lineHeight: 1 }}>
            {seconds}
          </span> : null}
      </div>

      {showHourLabel ? <HourLabel color={colors.labelColor} /> : null}

      {!showDateAboveTime ? renderDate() : null}
    </div>
  );
}