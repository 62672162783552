import { createContext, useEffect, useState } from 'react';
import { getFormattedDate, getSeconds, getHour, getMinutes, getNameOfDay, getDayOfMonth, getMonth } from '../tools';

export const TimeContext = createContext({
  date: '',
  shortDate: '',
  hour: '',
  minutes: '',
  seconds: '',
  day: '',
  dayNumber: '',
  month: '',
});

export default function TimeProvider({ children }) {
  const [date, setDate] = useState(getFormattedDate('long'));
  const [shortDate, setShortDate] = useState(getFormattedDate('short'));
  const [hour, setHour] = useState(getHour());
  const [minutes, setMinutes] = useState(getMinutes());
  const [seconds, setSeconds] = useState(getSeconds());
  const [day, setDay] = useState(getNameOfDay());
  const [dayNumber, setDayNumber] = useState(getDayOfMonth());
  const [month, setMonth] = useState(getMonth());

  useEffect(() => {
    /**
     * Update every second.
     */
    const interval = setInterval(() => {
      setDate(getFormattedDate('long'));
      setShortDate(getFormattedDate('short'));
      setHour(getHour());
      setMinutes(getMinutes());
      setSeconds(getSeconds());
      setDay(getNameOfDay());
      setDayNumber(getDayOfMonth());
      setMonth(getMonth());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return <TimeContext.Provider value={{ date, shortDate, hour, minutes, seconds, day, dayNumber, month }}>{children}</TimeContext.Provider>;
}
