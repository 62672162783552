import PictureGrid from '../grids/PictureGrid';
import ErrorMessage from '../messages/ErrorMessage';
import AnimationWrapper from '../wrappers/AnimationWrapper';

export default function Picture(props) {
  const { options } = props;
  const pictureUrls = [];

  for (const [, value] of Object.entries(options)) {
    if (value?.image?.url) {
      pictureUrls.push(value?.image?.url);
    }
  }

  return pictureUrls.length > 0
    ? (
      <AnimationWrapper animation={'zoom-out'}>
        <PictureGrid pictures={pictureUrls} />
      </AnimationWrapper>
    ) : <ErrorMessage message={"Selecteer tenminste één afbeelding om weer te geven."} />;
}
