import ErrorBoundary from '../ErrorBoundary';
import { NAME } from '../../constants';

export default function RoutingComponent(props) {
  const { Component, title = "" } = props;

  document.title = (title ? `${title} - ` : ``) + NAME;

  return (
    <ErrorBoundary>
      <Component />
    </ErrorBoundary>
  );
}
