import Image from '../images/Image';

export default function WeatherCard({ backgroundColor = 'transparent', fontColor = 'white', data: { day = '', temperature, icon }, compact = false}) {
  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: backgroundColor,
      }}
    >
      <div style={{display: 'flex'}}>
        <Image src={icon} alt={'weather_icon'} height={'30vmin'} width={'auto'} />
      </div>

      {/* Increase flexBasis to use more space in the container below,
      but look out for the image so that this container does not push the image upwards when the date span wraps. */}
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', flexBasis: '35%'}}>
        <span
          style={{
            fontSize: `${compact ? '12vmin' : '8vmin'}`,
            color: fontColor,
            fontWeight: 'bold',
          }}
        >
          {temperature}°
        </span>

        {!compact &&
          <span style={{fontSize: '3vmin', color: fontColor, marginTop: '1%', textAlign: 'center', fontWeight: 'bolder'}}>
            {day}
          </span>
        }
      </div>
    </div>
  );
}
