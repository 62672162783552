import Banner from './Banner';

export default function TextBanner(props) {
  const { header, title, content, isTextScrolling, backgroundColor = 'white' } = props;

  return (
    <Banner backgroundColor={backgroundColor} radius={5} autoScroll={isTextScrolling}>
      <div style={{ wordWrap: 'break-word' }}>
        {header &&
          <p style={{ fontSize: '2.5vmin', color: '#8A8A8A', marginBottom: "0px" }}>
            {header}
          </p>
        }

        {title &&
          <h1 style={{ fontSize: '4vmin', color: '#000', lineHeight: '6vmin', marginBottom: "0px" }}>
            {title}
          </h1>
        }

        <p style={{ fontSize: '3vmin', color: '#494949', lineHeight: '4.5vmin', textAlign: "justify", marginBottom: "0px" }}>
          {content}
        </p>
      </div>
    </Banner>
  );
}
