export default function MessageWrapper(props) {
  return (
    <div style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '10vmin',
      marginLeft: 'auto',
      marginRight: 'auto',
      fontFamily: 'Arial'
    }}>
      {props?.children}
    </div>
  );
}