import HtmlText from '../texts/HtmlText';
import Circle from '../circles/Circle';
import Smiley from '../smileys/Smiley';
import ImageBackground from '../backgrounds/ImageBackground';
import AnimationWrapper from '../wrappers/AnimationWrapper';

export default function Percentage(props) {
  const {
    options: { image_1, show_background_image, show_smiley, is_positive, is_mediocre, is_negative, title, text, current_percentage },
    colors: { primaryColor, secondaryColor },
  } = props;

  let smileySatisfaction;
  switch (true) {
    case !!parseInt(is_positive?.value):
      smileySatisfaction = 'positive';
      break;
    case !!parseInt(is_mediocre?.value):
      smileySatisfaction = 'mediocre';
      break;
    default:
      smileySatisfaction = 'negative';
  }

  return (
    <ImageBackground imageUrl={image_1?.image?.url ?? ''} showImage={!!parseInt(show_background_image?.value)}>
      <AnimationWrapper animation={'fade-in'}>
        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', }}>
          <div style={{
            display: 'flex',
            flexBasis: '40%',
            gap: '15%',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingLeft: '6%',
            overflow: 'hidden',
          }}>
            <HtmlText content={title?.value} />

            <HtmlText content={text?.value} />
          </div>

          <div style={{
            position: 'relative',
            display: 'flex',
            flexBasis: '60%',
            justifyContent: 'center',
            alignItems: 'flex-end',
            bottom: '5vmin',
            overflow: 'hidden',
          }}>
            <Circle border={'1.5vmin'} color={primaryColor} height={'80%'} width={'auto'} aspectRatio={'1 / 1'}>
              <h1 style={{ fontSize: '17.5vmin', color: secondaryColor }}>{current_percentage?.value ?? 0}%</h1>
            </Circle>

            {parseInt(show_smiley?.value) ? (
              <div style={{ position: 'absolute', width: 'auto', height: '30%', left: 0, aspectRatio: 1 / 1 }}>
                <AnimationWrapper animation={'roll-in'}>
                  <Smiley satisfaction={smileySatisfaction} />
                </AnimationWrapper>
              </div>
            ) : null}
          </div>
        </div>
      </AnimationWrapper>
    </ImageBackground>
  );
}
