import { useEasingGradient } from '../../hooks/useEasingGradient';

export default function TextOverlay(props) {
  const { title = '', overlayColor = 'rgba(0,44,255,1)' } = props;
  const { generateEasingCurve } = useEasingGradient();

  const easingCurve = generateEasingCurve('cubic-bezier(0.25, 1, 0.5, 1)', overlayColor, 'rgba(0,0,0,0)', 180);

  return (
    <div
      style={{
        position: 'absolute',
        bottom: '0%',
        height: '70%',
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        padding: '0 10vmin 3vmin 10vmin',
        background: easingCurve,
      }}
    >
      <h1 style={{
        color: '#fff',
        fontSize: '8vmin',
        margin: '0 0 1vmin 0',
        display: '-webkit-box',
        WebkitLineClamp: 3, // Allow 3 lines
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
      }}>{title}</h1>
    </div>
  );
}
