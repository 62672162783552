import { useContext } from 'react';
import { useQuery } from 'react-query';
import { fetchPlayer } from '../api/player';
import { PlaylistSettingsContext } from '../providers/playlistSettings';

export default function usePlayerData(id) {
  const { setPlaylistSettings } = useContext(PlaylistSettingsContext);

  return useQuery(['playlistSettings'], () => fetchPlayer(id), {
    onSuccess: data => setPlaylistSettings(data.playlist),
  });
}
