import ErrorMessage from '../components/messages/ErrorMessage';
import { NAME } from '../constants';

export default function Offline() {
  return (
    <ErrorMessage
      showAutoUpdateText={false}
      title={"Internetverbinding verloren!"}
      message={
        <>Zorg ervoor dat een actieve internetverbinding beschikbaar is voor {NAME} om te functioneren.</>
      }
    />
  );
}
