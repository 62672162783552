import { useContext } from 'react';
import { TimeContext } from '../../../providers/time';

export default function WeatherClock(props) {
  const { backgroundColor = "transparant", fontColor = "white" } = props;
  const { hour, minutes } = useContext(TimeContext);

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: backgroundColor,
        color: fontColor,
      }}
    >
      <span style={{ fontSize: `10vmin`, lineHeight: '80%' }}>
        {hour}:{minutes}
      </span>

      <span style={{ fontSize: `7.5vmin`, lineHeight: '80%' }}>
        uur
      </span>
    </div>
  );
}
