import Image from '../images/Image';
import Grid from './Grid';

export default function PictureGrid(props) {
  const { pictures } = props;

  return (
    <Grid columns={pictures.length} rows={1}>
      {pictures.map((url, index) => (
        <Image key={index} src={url} />
      ))}
    </Grid>
  );
}
