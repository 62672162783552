import React from 'react'

export default function AppBrand(props) {
  const { width = '400', height = '100' } = props;

  const textColor = '#1f1f1f';
  const imageColor = '#ea2a3d';

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 220.165 45.651">
  <g id="Group_152" data-name="Group 152" transform="translate(-80 -57.123)">
    <g id="Group_110" data-name="Group 110" transform="translate(131.172 67.492)">
      <path id="Path_1" data-name="Path 1" d="M223.8,47.857h7.449a21.278,21.278,0,0,1,5.141.6,11.769,11.769,0,0,1,4.25,1.958,9.6,9.6,0,0,1,2.881,3.517,11.8,11.8,0,0,1,1.066,5.252,10.72,10.72,0,0,1-1.035,4.823,10.368,10.368,0,0,1-2.785,3.5,12.312,12.312,0,0,1-4.043,2.149,15.691,15.691,0,0,1-4.807.732H223.8Zm4.966,17.953h2.578a13.866,13.866,0,0,0,3.2-.35,6.842,6.842,0,0,0,2.562-1.162,5.576,5.576,0,0,0,1.7-2.133,7.566,7.566,0,0,0,.621-3.231,6.3,6.3,0,0,0-.621-2.881,5.766,5.766,0,0,0-1.671-2.021,7.327,7.327,0,0,0-2.467-1.194,10.96,10.96,0,0,0-2.976-.4h-2.928Z" transform="translate(-223.802 -47.284)" fill={textColor}/>
      <rect id="Rectangle_1" data-name="Rectangle 1" width="4.966" height="22.537" transform="translate(24.096 0.573)" fill={textColor}/>
      <path id="Path_2" data-name="Path 2" d="M376.124,51.431a3.538,3.538,0,0,0-1.639-1.13,5.884,5.884,0,0,0-1.958-.366,5.143,5.143,0,0,0-1.114.127,4.273,4.273,0,0,0-1.082.4,2.356,2.356,0,0,0-.828.716,1.813,1.813,0,0,0-.318,1.082,1.793,1.793,0,0,0,.764,1.56,7.22,7.22,0,0,0,1.926.923q1.162.382,2.5.764a10.319,10.319,0,0,1,2.5,1.082,5.921,5.921,0,0,1,1.926,1.878,5.724,5.724,0,0,1,.764,3.151,7.249,7.249,0,0,1-.7,3.279,6.65,6.65,0,0,1-1.894,2.324,8.05,8.05,0,0,1-2.769,1.369,12.7,12.7,0,0,1-7.4-.223,10.2,10.2,0,0,1-3.5-2.165l3.533-3.883a5.15,5.15,0,0,0,1.926,1.576,5.5,5.5,0,0,0,2.4.557,5.437,5.437,0,0,0,1.226-.143,3.992,3.992,0,0,0,1.114-.43,2.4,2.4,0,0,0,.8-.732,1.826,1.826,0,0,0,.3-1.05,1.906,1.906,0,0,0-.78-1.607,7.2,7.2,0,0,0-1.957-1q-1.178-.413-2.547-.827a11.4,11.4,0,0,1-2.547-1.114,6.134,6.134,0,0,1-1.958-1.846,5.279,5.279,0,0,1-.78-3.024,6.772,6.772,0,0,1,.716-3.183,6.912,6.912,0,0,1,1.91-2.292,8.3,8.3,0,0,1,2.754-1.385,11.274,11.274,0,0,1,3.215-.462,12.65,12.65,0,0,1,3.692.541,8.525,8.525,0,0,1,3.215,1.814Z" transform="translate(-331.401 -45.351)" fill={textColor}/>
      <path id="Path_3" data-name="Path 3" d="M450.584,47.857h8.372a16.576,16.576,0,0,1,3.311.318,7.537,7.537,0,0,1,2.721,1.1,5.561,5.561,0,0,1,1.846,2.1,7.193,7.193,0,0,1,.685,3.327,7.679,7.679,0,0,1-.637,3.31,5.434,5.434,0,0,1-1.751,2.133,7.1,7.1,0,0,1-2.642,1.13,15.512,15.512,0,0,1-3.311.334H455.55v8.786h-4.966Zm4.966,9.55h3.311a6.388,6.388,0,0,0,1.289-.127,3.414,3.414,0,0,0,1.114-.43,2.288,2.288,0,0,0,.8-.827,2.618,2.618,0,0,0,.3-1.321,2.238,2.238,0,0,0-.4-1.385,2.653,2.653,0,0,0-1.019-.812,4.369,4.369,0,0,0-1.385-.366,14.287,14.287,0,0,0-1.464-.08H455.55Z" transform="translate(-398.731 -47.284)" fill={textColor}/>
      <path id="Path_4" data-name="Path 4" d="M539.127,47.857h4.966V65.81h9.2v4.584H539.127Z" transform="translate(-467.029 -47.284)" fill={textColor}/>
      <path id="Path_5" data-name="Path 5" d="M612.627,47.857h4.106l9.8,22.537h-5.6l-1.942-4.775h-8.754l-1.878,4.775h-5.475Zm1.91,6.557-2.738,7h5.507Z" transform="translate(-516.21 -47.284)" fill={textColor}/>
      <path id="Path_6" data-name="Path 6" d="M701.57,60.781,693.1,47.857h6.175l4.775,8.435,5.062-8.435H715l-8.467,12.924v9.613H701.57Z" transform="translate(-585.798 -47.284)" fill={textColor}/>
      <path id="Path_7" data-name="Path 7" d="M796.958,47.857H812.27v4.584H801.924v4.2H811.7v4.584h-9.772V65.81h10.918v4.584H796.958Z" transform="translate(-665.908 -47.284)" fill={textColor}/>
      <path id="Path_8" data-name="Path 8" d="M883.828,47.857h8.722a15.385,15.385,0,0,1,3.263.334,7.752,7.752,0,0,1,2.706,1.114,5.561,5.561,0,0,1,1.846,2.1,7.112,7.112,0,0,1,.685,3.295,6.615,6.615,0,0,1-1.242,4.059,5.588,5.588,0,0,1-3.629,2.117l5.729,9.518h-5.952l-4.711-9.008h-2.451v9.008h-4.966Zm4.966,9.327h2.929q.669,0,1.416-.048a4.44,4.44,0,0,0,1.353-.286,2.344,2.344,0,0,0,1-.748,2.278,2.278,0,0,0,.4-1.432,2.381,2.381,0,0,0-.35-1.369,2.343,2.343,0,0,0-.891-.78,3.974,3.974,0,0,0-1.241-.366,10.149,10.149,0,0,0-1.369-.1h-3.247Z" transform="translate(-732.915 -47.284)" fill={textColor}/>
    </g>
    <g id="Group_109" data-name="Group 109" transform="translate(80 57.123)">
      <path id="Path_9" data-name="Path 9" d="M165.47,95.528l-1.658.954c-.149-.758-.33-1.5-.547-2.229Z" transform="translate(-125.935 -72.703)" fill={imageColor}/>
      <path id="Path_10" data-name="Path 10" d="M146.157,87.575c.037.18.072.361.108.541l-1.641.951a23.656,23.656,0,0,0-2.126-6.8l2.549,1.471a26.37,26.37,0,0,1,1.109,3.84" transform="translate(-109.917 -63.455)" fill={imageColor}/>
      <path id="Path_11" data-name="Path 11" d="M33.118,58.656l-5.059,2.917a17.912,17.912,0,0,0-3.02-9.906,17.643,17.643,0,0,0-3.66-4.056.857.857,0,0,0-1.016-.136.793.793,0,0,0-.392.909,1.363,1.363,0,0,0,.412.589,16.44,16.44,0,0,1,4.7,6.345,16.333,16.333,0,0,1,1.259,7.247l-1.888,1.095A14.544,14.544,0,0,0,20.6,51.64,13.322,13.322,0,0,0,15.1,47.945,14.348,14.348,0,0,0,0,50.764V48.441c.34-.253.681-.505,1.038-.722a16.338,16.338,0,0,1,15.5-1.121c.057.021.118.051.175.073a.851.851,0,0,0,1.147-.412.815.815,0,0,0-.455-1.121c-.96-.372-1.925-.754-2.911-1.038a16.311,16.311,0,0,0-4.687-.614A17.917,17.917,0,0,0,0,46.381v-2.1a21.8,21.8,0,0,1,4.309-1.75A18.789,18.789,0,0,1,19.718,44.3a23.2,23.2,0,0,1,3.882,2.91,19.272,19.272,0,0,1,5.916,10.9c.119.63.506.98,1.007.887.531-.1.784-.552.661-1.2a21.125,21.125,0,0,0-3.237-8.022,24.174,24.174,0,0,0-2.162-2.766l3.975,2.3a23.006,23.006,0,0,1,3.36,9.348" transform="translate(0 -32.125)" fill={imageColor}/>
      <path id="Path_12" data-name="Path 12" d="M10.608,16.25l3.969,2.3c-.294-.064-.593-.132-.892-.189a13.98,13.98,0,0,0-1.4-.2c-.811-.072-1.632-.119-2.432-.176A22.936,22.936,0,0,0,0,20.366V18.4a26.006,26.006,0,0,1,2.6-1.027.85.85,0,1,0-.521-1.61q-1.061.348-2.075.783V14.661c.568-.238,1.141-.459,1.724-.651a25.782,25.782,0,0,1,3.484-.877l2.6,1.5c-.366.032-.748.063-1.134.1-.558.045-.889.329-.929.767a.847.847,0,0,0,.964.93c1.136-.082,2.276-.222,3.412-.2.165.006.33.011.491.014" transform="translate(0 -10.13)" fill={imageColor}/>
      <path id="Path_13" data-name="Path 13" d="M34.577,119.855a5.255,5.255,0,0,1-.154,2.061,28.262,28.262,0,0,0-.263,7.748l-1.7.98a28.079,28.079,0,0,1-.17-5.6c.087-1.357.34-2.7.516-4.057.056-.445.134-.883-.346-1.172a.842.842,0,0,0-1.269.525,30.524,30.524,0,0,0-.564,8.8c.068.806.141,1.621.249,2.427l-1.611.927a32.394,32.394,0,0,1-.309-9.24c.124-1.119.315-2.227.506-3.339a2.6,2.6,0,0,1,2.513-2.029,2.662,2.662,0,0,1,2.6,1.971" transform="translate(-22.184 -90.93)" fill={imageColor}/>
      <path id="Path_14" data-name="Path 14" d="M0,0,3.024,1.744A28.172,28.172,0,0,0,0,2.673Z" fill={imageColor}/>
      <path id="Path_15" data-name="Path 15" d="M0,96.966V86.131q.185-1.386.48-2.771a9.3,9.3,0,0,1,4.584-6.271.887.887,0,0,0,.53-.909.845.845,0,0,0-1.31-.589,11,11,0,0,0-3.006,2.462A10.793,10.793,0,0,0,0,79.9V76.784a13.409,13.409,0,0,1,5.518-3.7,9.552,9.552,0,0,1,3-.651,17.19,17.19,0,0,1,2.4-.042,13.983,13.983,0,0,1,2.726.557A12.73,12.73,0,0,1,22.5,82.262a12.54,12.54,0,0,1,.083,5.981c-.016.072-.031.141-.043.213L20.6,89.569c.067-.654.164-1.309.294-1.972a10.859,10.859,0,0,0-2.88-9.926,10.918,10.918,0,0,0-9.007-3.51.83.83,0,0,0-.833.878.846.846,0,0,0,.941.805c.247-.006.495-.021.63-.031a9.638,9.638,0,0,1,9.58,8.249,12.515,12.515,0,0,1-.212,3.877,23.956,23.956,0,0,0-.278,2.652L17,91.65a24.384,24.384,0,0,1,.2-3.118.84.84,0,0,0-.682-1,.811.811,0,0,0-.98.744c-.124,1.238-.207,2.48-.269,3.3.01.351.03.7.052,1.038l-1.719,1a22.783,22.783,0,0,1,.46-7.149A4.44,4.44,0,0,0,10.8,81.181a4.318,4.318,0,0,0-5.183,3.264,41.288,41.288,0,0,0-.63,4.49,34.229,34.229,0,0,0,.4,8.683l.294.567L3.922,99.2c-.2-1.077-.371-2.167-.562-3.246a.9.9,0,0,0-.95-.852.855.855,0,0,0-.749.991,34.149,34.149,0,0,0,.676,4.027L0,101.469Zm1.409-6.7c0,.408-.005.815,0,1.223a.851.851,0,0,0,.852.9.866.866,0,0,0,.841-.882c.125-1.74.217-3.479.4-5.208a13.366,13.366,0,0,1,.562-2.865,6.094,6.094,0,0,1,9.854-2.581A6.007,6.007,0,0,1,15.8,84.062a.9.9,0,0,0,.773.8.864.864,0,0,0,.895-.64,1.038,1.038,0,0,0,.014-.413,7.939,7.939,0,0,0-6.737-6.163,7.87,7.87,0,0,0-8.6,6.41,44.059,44.059,0,0,0-.728,6.216" transform="translate(0 -55.817)" fill={imageColor}/>
    </g>
  </g>
</svg>

  )
}
