import { useRef, useEffect } from 'react';

export default function Banner({ backgroundColor = 'white', radius = 0, style, height = '100%', autoScroll = false, paddings = '5%', children }) {
  const divRef = useRef();
  const timeoutRef = useRef(0);

  /**
   * Scroll the content of the div.
   */
  const scroller = function () {
    const element = divRef.current;

    if (element) {
      const { scrollTop, scrollHeight, clientHeight } = element;
      const nextScrollTop = scrollTop + 2;
      const remainingDistance = scrollHeight - nextScrollTop - clientHeight;

      if (remainingDistance <= 0) {
        timeoutRef.current = setTimeout(() => {
          element.scrollTo({ top: 0, behavior: 'smooth' });
          timeoutRef.current = setTimeout(scroller, 2000);
        }, 5000);
      } else {
        element.scrollTo({ top: nextScrollTop, behavior: 'smooth' });
        timeoutRef.current = setTimeout(scroller, 40);
      }
    }
  };

  /**
   * Enable the scroller on mount.
   */
  useEffect(() => {
    if (!autoScroll) {
      return;
    }

    timeoutRef.current = setTimeout(scroller, 2000);

    return () => clearTimeout(timeoutRef.current);
  }, [autoScroll]);

  return (
    <div
      ref={divRef}
      style={{
        height: height,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        backgroundColor: backgroundColor,
        borderRadius: radius,
        padding: paddings,
        ...style,
      }}
    >
      {children}
    </div>
  );
}
