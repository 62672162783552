import { Oval } from 'react-loader-spinner';

export default function Loader({ size = '20vmin' }) {
  return (
    <div style={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <Oval
        width={size}
        height={size}
        color='#228be6'
        visible={true}
        ariaLabel='oval-loading'
        secondaryColor="#228be6"
        strokeWidth={6}
        strokeWidthSecondary={6}
      />
    </div>
  );
}
