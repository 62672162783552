import TitleBar from './_TitleBar';
import Logo from './_Logo';
import { useContext } from 'react';
import { PlaylistSettingsContext } from '../../providers/playlistSettings';

export default function AppShell({ children }) {
  const { font } = useContext(PlaylistSettingsContext);

  return (
    <>
      {font &&
        <link rel="stylesheet" type="text/css" href={`https://fonts.googleapis.com/css?family=${font.replaceAll(' ', '+')}&display=swap`} />
      }

      <div style={{ width: '100vw', height: '100vh', fontFamily: `${font}, serif`, display: "flex", flexDirection: "column" }}>
        <Logo />

        <div style={{ position: "relative", flexGrow: 1, flexBasis: 0, overflow: 'hidden' }}>
          {children}
        </div>

        {/* Based on the order of the title _titleBar, the title _titleBar is either rendered below the content div or above. */}
        <TitleBar />
      </div>
    </>
  );
}
