import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { useEffect, useRef } from 'react';
import './fancyPlayer.css';

export default function FancyPlayer(props) {
  const { renderFunc, transitionTo, onEnter = () => {}, onExit = () => {} } = props;
  const nodeRef = useRef(null);

  /**
   * CSSTransition doesn't call the onEnter for the first time, so call it manually on mount.
   */
  useEffect(() => onEnter(), []);

  return (
    <SwitchTransition>
      <CSSTransition
        key={transitionTo}
        timeout={500}
        classNames="player"
        nodeRef={nodeRef}
        onEnter={onEnter}
        onExit={onExit}
      >
        <div ref={nodeRef} style={{ width: '100%', height: '100%' }}>
          {renderFunc(transitionTo)}
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
}