import FloatingWrapper from '../wrappers/FloatingWrapper';
import ImageWrapper from '../wrappers/ImageWrapper';
import Grid from '../grids/Grid';
import Image from '../images/Image';
import ColorBackground from '../backgrounds/ColorBackground';
import ErrorMessage from '../messages/ErrorMessage';
import AnimationWrapper from '../wrappers/AnimationWrapper';
import HtmlBanner from '../banners/HtmlBanner';

export default function Text(props) {
  const {
    options: { image_1, is_image_floating, is_text_floating, show_background_color, text_scrolling, subtitle, text, title },
    colors: { backgroundColor },
  } = props;

  const isTextFloating = !!parseInt(is_text_floating?.value);
  const isImageFloating = !!parseInt(is_image_floating?.value);
  const hasImage = !!image_1?.image?.url;
  const showBackgroundColor = !!parseInt(show_background_color?.value);

  if (!text?.value) {
    return <ErrorMessage message={"Controleer of een tekst is ingevoerd."} />;
  }

  return (
    <ColorBackground color={showBackgroundColor ? backgroundColor : 'white'}>
      <Grid columns={isTextFloating || !hasImage ? 1 : 2} rows={1}>
        <FloatingWrapper isFloating={isTextFloating}>
          <AnimationWrapper animation={'fade-in'}>
            <HtmlBanner
              header={subtitle?.value}
              title={title?.value}
              text={text?.value}
              isTextScrolling={!!parseInt(text_scrolling?.value)}
              backgroundColor={showBackgroundColor ? backgroundColor : 'white'}
            />
          </AnimationWrapper>
        </FloatingWrapper>

        {hasImage ? (
          <ImageWrapper center={isImageFloating}>
            <AnimationWrapper
              animation={'zoom-out'}
              style={{
                display: 'inline-grid',
                ...(isImageFloating ? { width: 'auto', height: 'auto' } : {})
              }}
            >
              <Image src={image_1?.image?.url} />
            </AnimationWrapper>
          </ImageWrapper>
        ) : null}
      </Grid>
    </ColorBackground>
  );
}