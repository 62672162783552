import Image from '../images/Image';
import { useContext, useEffect, useRef, useState } from 'react';
import { PlaylistSettingsContext } from '../../providers/playlistSettings';
import { ShellContext } from '../../providers/shell';
import AnimationWrapper from '../wrappers/AnimationWrapper';

export default function Logo() {
  const { logoUrl } = useContext(PlaylistSettingsContext);
  const { logoPosition } = useContext(ShellContext);

  const [animation, setAnimation] = useState('zoom-out');
  const [absolutePosition, setAbsolutePosition] = useState({});

  const timeoutRef = useRef(0);
  const wasHiddenRef = useRef(true);

  const updatePosition = () => {
    setAnimation('zoom-out');
    setAbsolutePosition(logoPosition === 'top_left' ? { left: '3vmin' } : { right: '3vmin' });
  }

  useEffect(() => {
    // On mount or if the logo was previously hidden, skip the fade-out animation.
    if (wasHiddenRef.current && logoPosition !== 'hidden') {
      wasHiddenRef.current = false;
      updatePosition();

      return;
    }

    // Logo changes from position or is going to hide, so perform fade-out animation.
    setAnimation('fade-out');

    // Abort if the logo is hidden.
    // The animation sets the opacity to 0, so do not change the position (and to prevent a rerender).
    if (logoPosition === 'hidden') {
      wasHiddenRef.current = true;
      return;
    }

    // Wait until the fade-out animation has been finished, then do the zoom-out animation and change the location.
    timeoutRef.current = window.setTimeout(updatePosition, 1000);
    wasHiddenRef.current = false;

    return () => window.clearTimeout(timeoutRef.current);
  }, [logoPosition]);

  return logoUrl ? (
    <AnimationWrapper animation={animation} style={{ width: 'auto', height: 'auto', zIndex: 200, }}>
      <div
        style={{
          position: 'absolute',
          visibility: absolutePosition?.left || absolutePosition?.right ? 'visible' : 'hidden', // Set initial visibility to hidden to prevent pop-in.
          top: '3vmin',
          ...absolutePosition,
        }}
      >
        <Image src={logoUrl} style={{ position: 'relative', maxWidth: '15vmin', maxHeight: '10vmin' }} />
      </div>
    </AnimationWrapper>
  ) : null;
}
