export default function FloatingWrapper(props) {
  const { children, isFloating = true} = props;

  return isFloating ? (
    <div
      style={{
        position: 'absolute',
        top: '20%',
        right: '50%',
        bottom: 'auto', // Allow growing based on content size.
        left: '10%',
        // transform: 'translate(0, -50%)',
        zIndex: 1,
      }}
    >
      {children}
    </div>
  ) : children;
}