import dayjs from 'dayjs';
import HtmlText from '../texts/HtmlText';
import AnimationWrapper from '../wrappers/AnimationWrapper';

export default function BirthdayCard(props) {
  const { date, text = '', backgroundColor = 'transparant' } = props;

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '5%', margin: '1%'}}>
      <AnimationWrapper animation={'roll-in'} style={{ width: 'auto', height: 'auto' }}>
        <div
          style={{
            borderRadius: '50%',
            backgroundColor: backgroundColor,
            width: '15vmin',
            height: '15vmin',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#fff',
            textAlign: 'center',
            lineHeight: 0.9,
            fontWeight: 700,
            fontSize: '5vmin',
          }}
        >
          <span>{dayjs(date)?.format('DD')}</span>
          <span>{dayjs(date)?.format('MM')}</span>

          <span style={{ marginTop: '0.2vmin', fontSize: '2.5vmin' }}>{dayjs(date)?.format('YYYY')}</span>
        </div>
      </AnimationWrapper>

      <AnimationWrapper animation={'fade-in'} style={{ maxWidth: '30vw', height: 'auto' }}>
        <HtmlText content={text} />
      </AnimationWrapper>
    </div>
  );
}