export default function Circle(props) {
  const { children, border = '1.25vmin', color ='gray', size = '100%', width, height, ...rest } = props;

  return (
    <div style={{
      width: width ?? size,
      height: height ?? size,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: border,
      borderStyle: 'solid',
      borderColor: color,
      borderRadius: '50%',
      overflow: 'hidden',
      ...rest
    }}>
      {children}
    </div>
  );
}