import { useEffect, useRef, useState } from 'react';
import FancyPlayer from './FancyPlayer';

export default function NewsPlayer({ renderFunc, count, start = 0, interval = 10, incrementer = 1 }) {
  const [iteration, setIteration] = useState(start % count);
  const iterationRef = useRef(start % count);
  const timerRef = useRef(null);

  /**
   * Set the interval.
   */
  useEffect(() => {
    timerRef.current = setInterval(() => {
      iterationRef.current = (iterationRef.current + incrementer) % count;

      setIteration(iterationRef.current);
    }, interval * 1000);

    return () => clearInterval(timerRef.current);
  }, []);

  return (
    <FancyPlayer renderFunc={renderFunc} transitionTo={iteration}>
      {renderFunc(iteration)}
    </FancyPlayer>
  );
}
