import { createContext, useState } from 'react';

export const PlaylistSettingsContext = createContext({
  setPlaylistSettings: _ => {},
  font: '',
  logoUrl: '',
  titleBar: {
    color: '#dbe8ef',
    position: 'bottom',
    weather: {},
    timestamp: {
      showTime: false,
      showDate: false,
    },
    feed: {},
  },
});

export default function PlaylistSettingsProvider({ children }) {
  const [font, setFont] = useState('');
  const [logoUrl, setLogoUrl] = useState('');
  const [titleBar, setTitleBar] = useState({});

  /**
   * Set all the properties of the currently showing playlist.
   * @param data
   */
  const setPlaylistSettings = function (data) {
    if (!data || !data?.company || !data?.playlist_settings || !data.playlist_settings?.title_bar) {
      return;
    }

    setFont(data.playlist_settings?.font);
    setLogoUrl(data.company.logo?.url);

    setTitleBar({
      color: data.playlist_settings.title_bar?.color,
      position: data.playlist_settings.title_bar?.position,
      weather: data.playlist_settings.title_bar?.weather?.current,
      timestamp: {
        showTime: data.playlist_settings.title_bar?.show_datetime,
        showDate: data.playlist_settings.title_bar?.show_datetime,
      },
      feed: data.playlist_settings.title_bar?.feed,
    });
  };

  return (
    <PlaylistSettingsContext.Provider
      value={{
        setPlaylistSettings,
        font,
        logoUrl,
        titleBar,
      }}
    >
      {children}
    </PlaylistSettingsContext.Provider>
  );
}
