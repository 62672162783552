import * as dayjs from 'dayjs';
import 'dayjs/locale/nl';
import * as isLeapYear from 'dayjs/plugin/isLeapYear';
import Birthday from './components/screens/Birthday';
import News from './components/screens/News';
import Picture from './components/screens/Picture';
import Text from './components/screens/Text';
import Time from './components/screens/Time';
import Video from './components/screens/Video';
import Weather from './components/screens/Weather';
import Webpage from './components/screens/Webpage';
import Error from './pages/Error';
import Percentage from './components/screens/Percentage';

// Configure DayJS.
dayjs.extend(isLeapYear);
dayjs.locale('nl');

/**
 * Get a value from the .env.
 * @param name
 * @param defaultValue
 * @returns {string}
 */
export function env(name, defaultValue = '') {
  return process.env[`REACT_APP_${name}`] || defaultValue;
}

export function getFormattedDate(format = 'short') {
  if (format === 'long') {
    return dayjs().format('dddd DD MMMM');
  }

  return dayjs().format('dd DD MMM');
}

export function getHour() {
  return dayjs().format('HH');
}

export function getMinutes() {
  return dayjs().format('mm');
}

export function getSeconds() {
  return dayjs().format('ss');
}

export function getDayOfMonth() {
  return dayjs().format('DD');
}

export function getNameOfDay() {
  return dayjs().format('dddd');
}

export function getMonth() {
  return dayjs().format('MMMM');
}

export const screenComponentFactory = (props) => {
  const type = props?.template?.type;
  props = formatScreenOptions(props);

  if (type === 'picture') return <Picture {...props} />;
  if (type === 'news') return <News {...props} />;
  // if (type === 'social_media') return <SocialMedia {...props} />;
  if (type === 'time') return <Time {...props} />;
  if (type === 'video') return <Video {...props} />;
  if (type === 'weather') return <Weather {...props} />;
  if (type === 'iframe') return <Webpage {...props} />;
  if (type === 'text') return <Text {...props} />
  if (type === 'birthday') return <Birthday {...props} />;
  if (type === 'percentage') return <Percentage {...props} />;

  return <Error />;
};

export function shadeColor(color, amount) {
  return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
}

export const formatScreenOptions = (data) => {
  if (!data?.template?.options || !data?.screen_options) {
    return {};
  }

  let formatted = {
    options: [],
    colors: {
      primaryColor: data?.primary_color ?? 'black',
      secondaryColor: data?.secondary_color ?? 'black',
      backgroundColor: data?.background_color ?? 'white',
    }
  };

  for (const option of data.template.options) {
    formatted.options[option.name] = data.screen_options.find((o) => (o?.option_id ?? -1) === option.id);
  }

  return formatted;
}

/**
 * Checks if the given string matches the query.
 *
 * @param str string to check.
 * @param matchesWith regex OR to check string.
 * @param fallback fallback value if there's no match.
 * @returns {string} given str if there's a match, otherwise the fallback.
 */
export const matchOrDefault = (str = '', matchesWith = '', fallback= '') => {
  return str?.match(matchesWith) ? str : fallback;
}