import RouteCollection from './components/routing/RouteCollection';
import AppShell from './components/shell/AppShell';
import { useEffect, useState } from 'react';
import Offline from './pages/Offline';

export default function App() {
  const [connectionStatus, setConnectionStatus] = useState(navigator.onLine);

  const onlineHandler = () => window.location.reload();
  const offlineHandler = () => setConnectionStatus(false);

  useEffect(() => {
    window.addEventListener('online', onlineHandler);
    window.addEventListener('offline', offlineHandler);

    return () => {
      window.removeEventListener('online', onlineHandler);
      window.removeEventListener('offline', offlineHandler);
    };
  }, []);

  if(!connectionStatus) return <Offline />;

  return (
    <AppShell>
      <RouteCollection />
    </AppShell>
  );
}
