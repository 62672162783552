import { NAME } from '../../constants';
import AppBrand from '../AppBrand';
import MessageWrapper from '../wrappers/MessageWrapper';
import { useContext, useEffect } from 'react';
import { ShellContext } from '../../providers/shell';
import AnimationWrapper from '../wrappers/AnimationWrapper';

export default function ErrorMessage(props) {
  const {
    title = "Deze dia kan niet worden weergegeven.",
    message = "Zijn alle vereiste velden voor de dia ingevuld?",
    showAutoUpdateText = true,
  } = props;

  const { updateShell } = useContext(ShellContext);

  useEffect(() => {
    updateShell(null);
  }, [updateShell]);

  return (
    <AnimationWrapper animation={'blur-out'}>
      <MessageWrapper>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <AppBrand width={"75vmin"} height={"20vmin"} />
        </div>

        {title ? <h1 style={{ fontSize: '7vmin', margin: 0, marginTop: "1rem" }}>{title}</h1> : null}

        <div style={{ fontSize: '4vmin', marginTop: '5vmin' }}>
          {message ? <p style={{ margin: 0 }}>{message}</p> : null}

          {showAutoUpdateText ?
            <p style={{ marginTop: '3vmin' }}>Geen zorgen, {NAME} hoeft niet opnieuw opgestart te worden. {NAME} wordt namelijk automatisch bijgewerkt.</p>
            : null}

          <p style={{ fontSize: '3.5vmin' }}>Neem voor vragen contact met ons op via support@webba.nl of telefonisch via 0528 - 26 95 94.</p>
        </div>
      </MessageWrapper>
    </AnimationWrapper>
  );
}