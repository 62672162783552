import { useContext } from 'react';
import { TimeContext } from '../../../providers/time';
import HourLabel from './_hourLabel';

export default function Compact(props) {
  const {
    showSeconds,
    showDateAboveTime,
    useShortDate,
    showHourLabel,
    colors = { timeColor: '', dateColor: '', labelColor: '' }
  } = props;

  const { hour, minutes, seconds, day, dayNumber, month, shortDate } = useContext(TimeContext);

  const renderDate = () => {
    return (
      <div style={{ color: colors.dateColor, display: 'flex', flexDirection: 'column' }}>
        {useShortDate
          ? <span style={{ fontSize: '5vmin', lineHeight: '1', height: '5vmin' }}>{shortDate.toUpperCase()}</span>
          : (
            <>
              <span style={{ fontSize: '5vmin', lineHeight: 1, height: '5vmin' }}>{day}</span>
              <span style={{ fontSize: '5vmin', lineHeight: 1, height: '5vmin' }}>{dayNumber} {month}</span>
            </>
        )}
      </div>
    );
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

      <div style={{ color: colors.timeColor, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '2vmin', height: '30vmin' }}>

        <span style={{ fontSize: '30vmin', height: '30vmin', lineHeight: 1 }}>
          {hour}
        </span>

        <div style={{ display: 'flex', flexDirection: 'column', }}>
          {showDateAboveTime ? renderDate() : null}

          <div>
            <span style={{ fontSize: '16vmin', height: '16vmin', display: 'inline-block', lineHeight: 1 }}>
              {minutes}
            </span>

            {showSeconds ?
              <span style={{ fontSize: '8vmin', lineHeight: 1, display: 'inline-block', width: '9vmin', height: '8vmin' }}>
                {seconds}
              </span>
              : null}
          </div>

          {!showDateAboveTime ? renderDate() : null}
        </div>

      </div>

      {showHourLabel ? <HourLabel color={colors.labelColor} /> : null}
    </div>
  );
}