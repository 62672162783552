
export default function Grid({ columns, rows, height = '100%', padding = '0', spaceBetween = 0, children, ...restProps }) {

  return (
    <div
      style={{
        display: 'grid',
        height: height,
        padding: padding,
        gridGap: spaceBetween,
        gridTemplateColumns: `${'minmax(0, 1fr) '.repeat(columns)}`,
        gridTemplateRows: `${'minmax(0, 1fr) '.repeat(rows)}`,
      }}
      {...restProps}
    >
      {children}
    </div>
  );
}
