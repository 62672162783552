import { Route, Routes } from 'react-router-dom';
import NotFound from '../../pages/NotFound';
import Player from '../../pages/Player';
import Screen from '../../pages/Screen';
import Playlist from '../../pages/Playlist';
import RoutingComponent from './RoutingComponent';

export default function RouteCollection() {
  return (
    <Routes>
      <Route path="/screen/:id" element={<RoutingComponent Component={Screen} title={"Preview"} />} />
      <Route path="/playlist/:id" element={<RoutingComponent Component={Playlist} title={"Preview"} />} />
      <Route path="/player/:id" element={<RoutingComponent Component={Player} title={"Speler"} />} />

      <Route path="*" element={<RoutingComponent Component={NotFound} title={"404"} />} />
    </Routes>
  );
}
