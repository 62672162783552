import ColorBackground from '../backgrounds/ColorBackground';
import Clock from '../clocks/Clock';
import AnimationWrapper from '../wrappers/AnimationWrapper';

export default function Time(props) {
  const {
    options: { is_compact, show_date_above_time, show_seconds, show_background_color, show_hour_label, use_short_date },
    colors: { primaryColor, secondaryColor, backgroundColor }
  } = props;

  return (
    <ColorBackground color={parseInt(show_background_color?.value) ? backgroundColor : "white"}>
      <AnimationWrapper animation={'fade-in'}>
        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Clock
            colors={{timeColor: primaryColor, dateColor: secondaryColor, labelColor: primaryColor}}
            isCompact={parseInt(is_compact?.value)}
            showDateAboveTime={parseInt(show_date_above_time?.value)}
            showSeconds={parseInt(show_seconds?.value)}
            showHourLabel={parseInt(show_hour_label?.value)}
            useShortDate={parseInt(use_short_date?.value)}
          />
        </div>
      </AnimationWrapper>
    </ColorBackground>
  );
}
