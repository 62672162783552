import ImageBackground from '../backgrounds/ImageBackground';
import BirthdayCard from '../cards/BirthdayCard';
import ErrorMessage from '../messages/ErrorMessage';

export default function Birthday(props) {
  const {
    options,
    colors: { primaryColor },
  } = props;
  const dates = [];

  // Bind each date with their description.
  for (const option in options) {
    if (!option.includes('date') || !options[option]?.value) {
      continue;
    }

    dates.push({
      date: options[option]?.value,
      description: options[`description_${option[option.length - 1]}`]?.value,
    });
  }

  if (dates.length < 1) {
    return <ErrorMessage message={"Vul minstens 1 datum in om te tonen."} />
  }

  return (
    <ImageBackground imageUrl={parseInt(options?.show_background_image?.value) && (options?.image_1?.image?.url ?? '')}>
      <div style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignContent: 'center'}}
      >
        {dates.map((date, index) =>
          <BirthdayCard key={index} date={date?.date} text={date?.description} backgroundColor={primaryColor} />)}
      </div>
    </ImageBackground>
  );
}
