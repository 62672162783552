import useScreenData from '../hooks/useScreenData';
import { useContext, useEffect, useRef, useState } from 'react';
import { ShellContext } from '../providers/shell';
import { screenComponentFactory } from '../tools';
import { useParams } from 'react-router-dom';
import ErrorMessage from '../components/messages/ErrorMessage';
import FullPageLoader from '../components/spinners/FullPageLoader';

export default function Screen() {
  const { id } = useParams();
  const { data, isLoading, refetch } = useScreenData(id);
  const { updateShell } = useContext(ShellContext);
  const [screen, setScreen] = useState();
  const timeoutRef = useRef(0);

  useEffect(() => {
    if (!isLoading && data) {
      setScreen(data);

      updateShell(data);

      timeoutRef.current = window.setTimeout(refetch, 30000);
    }

    return () => window.clearTimeout(timeoutRef.current);
  }, [data, isLoading]);

  if (isLoading) {
    return <FullPageLoader />;
  }

  return !screen
    ? <ErrorMessage message={"Controleer of deze dia bestaat."} showAutoUpdateText={false} />
    : screenComponentFactory(screen);
}