import { useParams } from 'react-router-dom';
import usePlayerData from '../hooks/usePlayerData';
import { useEffect, useRef, useState } from 'react';
import PlaylistPlayer from '../components/players/PlaylistPlayer';
import ErrorMessage from '../components/messages/ErrorMessage';
import FullPageLoader from '../components/spinners/FullPageLoader';

export default function Player() {
  const { id } = useParams();
  const { data, isLoading, refetch, isRefetching } = usePlayerData(id);
  const [playlist, setPlaylist] = useState();
  const timeoutRef = useRef(0);

  /**
   * Retrieve playlist data.
   */
  useEffect(() => {
    if (!isLoading && data) {
      // Playlist can be excluded from the response if the player doesn't have a playlist.
      setPlaylist(data?.playlist);
    }
  }, [isLoading, data]);

  /**
   * Refetch every 30 seconds when there is no location or playlist with screens received.
   * Is also triggered when a refetch is done from the PlaylistPlayer.
   */
  useEffect(() => {
    // Only when the initial fetch didn't receive any data and the data is not currently refetched (prevent leak).
    if (!isLoading && (data?.playlist?.screens?.length ?? 0) < 1 && !isRefetching) {
      timeoutRef.current = window.setTimeout(refetch, 30000);
    }

    return () => window.clearTimeout(timeoutRef.current);
  }, [isLoading, data, isRefetching]);

  if (isLoading) {
    return <FullPageLoader />
  }

  return (playlist?.screens?.length ?? 0) < 1
    ? <ErrorMessage
      title={"De afspeellijst van deze locatie kan niet worden weergegeven."}
      message={"Controleer of deze locatie bestaat en een afspeellijst bevat met geactiveerde dia's om te tonen."}
      showAutoUpdateText={!!data}
    />
    : <PlaylistPlayer screens={playlist.screens} refetch={refetch} />
}
