import ErrorMessage from '../messages/ErrorMessage';
import { useState } from 'react';
import Loader from '../spinners/Loader';
import AnimationWrapper from '../wrappers/AnimationWrapper';

export default function Webpage(props) {
  const {
    options: { url_1 }
  } = props;

  const [isLoading, setIsLoading] = useState(true);
  const url = url_1.value.substring(0, 4).toLowerCase() !== 'http' ? `https://${url_1?.value}` : url_1?.value;

  if (!url_1?.value) {
    return <ErrorMessage message={"Stel een webpagina in om te tonen."} />;
  }

  return (
    <AnimationWrapper animation={'fade-in'}>
      {isLoading && <Loader />}
      <iframe
        frameBorder="0"
        scrolling="no"
        title={url}
        src={url}
        onLoad={() => setIsLoading(false)}
        style={{ width: '100vw', height: '100vh' }}
      />
    </AnimationWrapper>
  );
}
