import { createContext, useState } from 'react';

export const ShellContext = createContext({
  showTitleBar: false,
  logoPosition: 'hidden',
  updateShell: (screen) => { }
});

export default function ShellProvider({ children }) {
  const [showTitleBar, setShowTitleBar] = useState(false);
  const [logoPosition, setLogoPosition] = useState('hidden');

  const updateShell = function (screen) {
    setShowTitleBar(screen?.has_titlebar ?? false);
    setLogoPosition(screen?.logo_position ?? 'hidden');
  };

  return (
    <ShellContext.Provider value={{ showTitleBar, logoPosition, updateShell }}>
      {children}
    </ShellContext.Provider>
  );
}
