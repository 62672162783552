import './animations.css';
import { matchOrDefault } from '../../tools';
import { ANIMATIONS } from '../../constants';
import { useEffect, useRef } from 'react';

export default function AnimationWrapper(props) {
  const { children, animation, style = {}, onAnimated = () => {} } = props;
  const timeoutRef = useRef(0);

  useEffect(() => {
    // For now, all animations have a duration of 1s.
    // Add duration as prop in the future and set via this component instead of via the CSS file.
    timeoutRef.current = window.setTimeout(onAnimated, 1000);
    return () => window.clearTimeout(timeoutRef.current);
  }, []);

  return (
    <div className={matchOrDefault(animation, ANIMATIONS, 'blur-out')} style={style}>
      {children}
    </div>
  );
}