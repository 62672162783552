import { useQuery } from 'react-query';
import { fetchPlaylist } from '../api/playlist';
import { useContext } from 'react';
import { PlaylistSettingsContext } from '../providers/playlistSettings';

export default function usePlaylistData(id) {
  const { setPlaylistSettings } = useContext(PlaylistSettingsContext);

  return useQuery(['playlistSettings'], () => fetchPlaylist(id), {
    onSuccess: data => setPlaylistSettings(data),
  });
}
