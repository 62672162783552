import './smiley.css';
import { matchOrDefault } from '../../tools';

export default function Smiley(props) {
  const { satisfaction, width = '100%', height = '100%', } = props;
  const checkedSatisfaction = matchOrDefault(satisfaction, 'positive|mediocre|negative', 'positive');

  return (
    <div className={`face ${checkedSatisfaction}`} style={{ width: width, height: height }}>
      <div className={'eye left'}></div>
      <div className={'eye right'}></div>

      <div className={`mouth ${checkedSatisfaction}`}></div>
    </div>
  );
}
