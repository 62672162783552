import ErrorMessage from '../components/messages/ErrorMessage';

export default function NotFound() {
  return (
    <ErrorMessage
      showAutoUpdateText={false}
      title={"Pagina niet gevonden."}
      message={"Zo te zien ben je verdwaald! Controleer of het juiste adres is ingevoerd."}
    />
  );
}
