import { easingCoordinates } from 'easing-coordinates';
import chroma from 'chroma-js';

export const useEasingGradient = () => {
  const transparentFix = colors =>
    colors.map((color, i) => {
      return color === 'transparent'
        ? chroma(colors[Math.abs(i - 1)])
            .alpha(0)
            .css('hsl')
        : color;
    });

  const hasParenthesis = str => str.indexOf('(') !== -1;

  const getBeforeParenthesisMaybe = str => (hasParenthesis(str) ? str.substring(0, str.indexOf('(')) : str);

  const getParenthesisInsides = str => str.match(/\((.*)\)/).pop();

  const roundHslAlpha = (color, alphaDecimals) => {
    const prefix = getBeforeParenthesisMaybe(color);
    const values = getParenthesisInsides(color)
      .split(',')
      .map(string => (string.indexOf('%') === -1 ? +Number(string).toFixed(alphaDecimals) : string.trim()));
    color = `${prefix}(${values.join(', ')})`;
    return color;
  };

  function getColorStops(colors, coordinates, alphaDecimals = 5, colorMode = 'lrgb') {
    const colorStops = [];
    colors = transparentFix(colors);
    coordinates.forEach(coordinate => {
      const ammount = coordinate.y;
      const percent = coordinate.x * 100;
      let color = chroma.mix(colors[0], colors[1], ammount, colorMode).css('hsl');
      color = roundHslAlpha(color, alphaDecimals);
      if (Number(coordinate.x) !== 0 && Number(coordinate.x) !== 1) {
        colorStops.push(`${color} ${+percent.toFixed(2)}%`);
      } else {
        colorStops.push(color);
      }
    });

    return colorStops;
  }

  function generateEasingCurve(easingFunction, colorOne, colorTwo, degrees = 0, steps = 16) {
    const coordinates = easingCoordinates(easingFunction, steps - 1);
    const colorStops = getColorStops([colorTwo, colorOne], coordinates);

    return `linear-gradient(${degrees}deg, ${colorStops.join(', ')})`;
  }

  return { generateEasingCurve };
};
