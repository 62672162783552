import { DEFAULT_IMAGE_URL } from '../../constants';
import AnimationWrapper from '../wrappers/AnimationWrapper';

export default function ImageBackground({ children, imageUrl = DEFAULT_IMAGE_URL, showImage = true, showBlur = false, opacity = 1 }) {
  return showImage ? (
    <AnimationWrapper animation={'blur-out'}>
      <div style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundSize: 'cover',
        backgroundImage: `url(${imageUrl})`,
        opacity: opacity,
        filter: showBlur ? 'blur(1px) brightness(0.8)' : '',
      }}>
      </div>

      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        {children}
      </div>
    </AnimationWrapper>
  ) : children;
}
