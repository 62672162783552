import AnimationWrapper from '../wrappers/AnimationWrapper';

export default function ColorBackground({ color = '#fff', children }) {
  return (
    <AnimationWrapper animation={'blur-out'}>
      <div style={{ width: '100%', height: '100%', backgroundColor: color }}>
        {children}
      </div>
    </AnimationWrapper>
  );
}
