import Compact from './_clock/_compact';
import Extended from './_clock/_extended';

export default function Clock(props) {
  const {
    isCompact,
    showDateAboveTime,
    showSeconds,
    showHourLabel,
    useShortDate,
    colors = { timeColor: '', dateColor: '', labelColor: '' }
  } = props;

  return isCompact ? (
    <Compact
      showSeconds={showSeconds}
      showDateAboveTime={showDateAboveTime}
      useShortDate={useShortDate}
      showHourLabel={showHourLabel}
      colors={colors}
    />
  ) : (
    <Extended
      showSeconds={showSeconds}
      showDateAboveTime={showDateAboveTime}
      useShortDate={useShortDate}
      showHourLabel={showHourLabel}
      colors={colors}
    />
  )
}
