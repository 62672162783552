import { useContext } from 'react';
import { TimeContext } from '../../../providers/time';

export default function DateTime() {
  const { day, dayNumber, month, hour, minutes } = useContext(TimeContext);

  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        color: 'white',
        gap: '15px',
        fontSize: '2vmin',
        flexShrink: 0
      }}
    >
      <div style={{ display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'center' }}>
        <h3 style={{ margin: '0', lineHeight: '100%' }}>{day}</h3>
        <h3 style={{ margin: '0', lineHeight: '100%' }}>{`${dayNumber} ${month}`}</h3>
      </div>

      <h1 style={{ margin: '0' }}>{`${hour}:${minutes}`}</h1>
    </div>
  );
}
