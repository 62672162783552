import { useRef, useEffect } from 'react';
import { sanitize } from 'dompurify';
import './htmlText.css';

export default function HtmlText(props) {
  const { content = '', style } = props;
  const nodeRef = useRef(null);

  useEffect(() => {
    const elements = nodeRef.current.children;

    Array.from(elements).forEach((element, index) => {
      // If the element is a paragraph and it's empty, replace it with a non-breaking space.
      if (element.tagName === 'P') {
        element.innerHTML = element.innerHTML ? element.innerHTML : '&nbsp;'
      }
    });
  }, [content]);

  return <div
    ref={nodeRef}
    className={'html-text'}
    style={style}
    dangerouslySetInnerHTML={{ __html: sanitize(content) }}
  />
};
