import { useParams } from 'react-router-dom';
import usePlaylistData from '../hooks/usePlaylistData';
import { useEffect, useRef, useState } from 'react';
import PlaylistPlayer from '../components/players/PlaylistPlayer';
import ErrorMessage from '../components/messages/ErrorMessage';
import FullPageLoader from '../components/spinners/FullPageLoader';

export default function Playlist() {
  const { id } = useParams();
  const { data, isLoading, refetch, isRefetching } = usePlaylistData(id);
  const [screens, setScreens] = useState();
  const timeoutRef = useRef(0);

  /**
   * Initial fetch.
   */
  useEffect(() => {
    if (!isLoading && data?.screens) {
      setScreens(data.screens);
    }
  }, [isLoading, data]);

  /**
   * Refetch every 30 seconds if this playlist is invalid or if there are no screens.
   * Is also triggered when a refetch is done from the PlaylistPlayer.
   */
  useEffect(() => {
    // Only when the initial fetch didn't receive any data and the data is not currently refetched (prevent leak).
    if (!isLoading && (data?.screens?.length ?? 0) < 1 && !isRefetching) {
      timeoutRef.current = window.setTimeout(refetch, 30000);
    }

    return () => window.clearTimeout(timeoutRef.current);
  }, [isLoading, data, isRefetching]);

  if (isLoading) {
    return <FullPageLoader />;
  }

  return (screens?.length ?? 0) < 1
    ? <ErrorMessage
        title={"Deze afspeellijst kan niet worden weergegeven."}
        message={"Controleer of de afspeellijst bestaat en geactiveerde dia's bevat om te tonen."}
        showAutoUpdateText={!!screens}
      />
    : <PlaylistPlayer screens={screens} refetch={refetch} />;
}