import { useContext, useEffect, useRef } from 'react';
import { PlaylistSettingsContext } from '../../../providers/playlistSettings';
import Ticker from 'react-ticker';
import Image from '../../images/Image';
import './ticker.css';
import { NAME } from '../../../constants';
import useElementSize from '../../../hooks/useElementSize';

export default function NewsTicker() {
  const { titleBar: { feed, color } } = useContext(PlaylistSettingsContext);

  const tickerRef = useRef(null);
  const { width } = useElementSize(tickerRef);

  /**
   * Trigger resize event on rerender to render the ticker again.
   * Required to fix a bug wherein the ticker is not rendered.
   */
  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  });

  const renderTitle = function (story) {
    return (
      <p
        style={{
          fontSize: '5vmin',
          color: 'white',
          whiteSpace: 'nowrap',
          margin: '0 15vmin 0 0',
        }}
      >
        {story ?? `Nieuwsinformatie van ${NAME} wordt hier getoond. Momenteel kan nieuwsinformatie niet worden verkregen.`}
      </p>
    );
  };

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex' }}>
      <style>
        {`.ticker::before {
            background-image: linear-gradient(to right, ${color}, transparent);
        }
        .ticker::after {
            background-image: linear-gradient(to left, ${color}, transparent);
        }`}
      </style>

      {/** News source logo */}
      <div style={{ display: 'flex', height: '100%', alignItems: 'center', margin: '0px 10px 0px 10px' }}>
        {feed?.logo
          ? <Image
            src={feed?.logo}
            alt={'Source'}
            width={"auto"}
            height={"auto"}
            style={{ maxWidth: "6vw", maxHeight: "6vh" }} />
          : null}
      </div>

      {/** News ticker */}
      <div ref={tickerRef} style={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center' }}>
        {feed?.titles && (
          <Ticker move={true} offset={'run-in'} speed={width > 700 ? width * 0.004 : 3}>
            {({index}) => renderTitle(feed?.titles[index % feed?.titles?.length])}
          </Ticker>
        )}
      </div>
    </div>
  );
}
