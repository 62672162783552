import Grid from './Grid';
import WeatherCard from '../cards/WeatherCard';
import WeatherClock from './weather/_weatherClock';
import { shadeColor } from '../../tools';

export default function WeatherGrid(props) {
  const {
    weatherData = [],
    showWeatherCardColor = false,
    showTime = false,
    showCompact = false,
    evenColor = '#FFFFFF',
    unevenColor = '#F1F3F4',
  } = props;

  const contentLength = weatherData.length + (showTime && 1);

  // Calculate the number of rows needed to display the content, with a maximum of 4 columns per row.
  // For example, if the contentLength is 8, it should be split into 2 rows (8 / 4 = 2).
  // If the contentLength is not evenly divisible by 4, add an extra row to accommodate the remaining items.
  // For example, if the contentLength is 7, it will be split into 2 rows (7 / 4 = 1, remainder 3, so +1).
  const rows = Math.floor(contentLength / 4) + (contentLength % 4 > 0 && 1);

  // Based on the number of rows, determine the number of columns needed to display the content.
  // To ensure enough columns for the contentLength, ceil the result.
  // If the number of rows is 0, fallback to 1 to avoid division by zero.
  const columns = Math.ceil(contentLength / (rows ?? 1));

  // Helpers to determine card color.
  let useEvenColor = false;
  let firstColumnIsEven = false;

  const renderWeatherCard = function (data, index) {
    if (!showWeatherCardColor) {
      return <WeatherCard
        key={index}
        data={data}
        compact={showCompact}
        fontColor={evenColor}
      />;
    }

    // Toggle between colors.
    useEvenColor = !useEvenColor;

    // Use the opposite color on a new column based on the column above.
    if ((index + 1) % columns === 1) {
       useEvenColor = firstColumnIsEven = !firstColumnIsEven;
    }

    return <WeatherCard
      key={index}
      backgroundColor={useEvenColor ? evenColor : unevenColor}
      fontColor={"white"}
      data={data}
      compact={showCompact}
    />;
  };

  return (
    <Grid columns={columns} rows={rows}>
      {weatherData.map((value, index) => renderWeatherCard(value, index))}

      {showTime &&
        <WeatherClock
          backgroundColor={showWeatherCardColor ? shadeColor(evenColor, -30) : 'transparent'}
          fontColor={showWeatherCardColor ? "white" : evenColor}
        />}
    </Grid>
  );
}
