import ErrorMessage from '../components/messages/ErrorMessage';
import { NAME } from '../constants';

export default function Error() {
  return (
    <ErrorMessage
      showAutoUpdateText={false}
      title={"Oeps, er is iets fout gegaan!"}
      message={
        <>
          Maak je geen zorgen, de ontwikkelaars zijn al op de hoogte gesteld en er wordt gewerkt aan een oplossing.<br/><br/>Start {NAME} alstublieft opnieuw op.
        </>
      }
    />
  );
}
