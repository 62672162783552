import { useContext } from 'react';
import { PlaylistSettingsContext } from '../../../providers/playlistSettings';

export default function Temperature() {
  const { titleBar: { weather } } = useContext(PlaylistSettingsContext);

  return weather?.temperature ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '12.5vmin',
        height: '100%', }}
    >
      <h1 style={{ margin: '0', color: 'white', fontSize: '4vmin' }}>{weather?.temperature} °C</h1>
    </div>
  ) : null;
}
