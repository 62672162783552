import Banner from './Banner';
import HtmlText from '../texts/HtmlText';

export default function HtmlBanner(props) {
  const { header, title, text, isTextScrolling, backgroundColor = 'white' } = props;

  return (
    <Banner backgroundColor={backgroundColor} radius={5} autoScroll={isTextScrolling}>
      {header && <HtmlText content={header} />}

      {title && <HtmlText content={title} />}

      {text && <HtmlText content={text} />}
    </Banner>
  );
}